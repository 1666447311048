<template>
    <div>
        <div class="toolBar mBottom10">
            <el-cascader v-show="global.isAdmin" :props="global.orgTreeProps" :options="global.orgList" :show-all-levels="false" v-model="global.orgId" @change="handleChange"
                         size='small' style="margin-right: 20px"></el-cascader>
            <el-button plain size='small' class='fr' @click="load">刷新</el-button>
        </div>

<!--        <el-tabs type="border-card" v-model="tabIndex" @tab-click="load">-->
<!--            <el-tab-pane label="医院配置">-->
        <el-card>
                <el-form ref="form" :model="hospitalConfig" :disabled="isDisabled" :rules="rules" label-width="160px">
                    <el-row>
                        <el-col :sm="12">
                            <el-form-item label="医院名称:" prop="name">
                                <el-input clearable :disabled="isDisabled" v-model="hospitalConfig.name" size='small'></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12">
                            <el-form-item label="医院电话:" prop="phone">
                                <el-input clearable :disabled="isDisabled" maxlength="16" v-model="hospitalConfig.phone"
                                          size='small'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :sm="12">
                            <el-form-item label="医院等级:" prop="level">
                                <el-select v-model="hospitalConfig.level" placeholder="请选择" class='maxWidth'
                                           size='small'>
                                    <el-option :disabled="isDisabled" v-for="item in global.DictionaryData['hospitalLevel']" :key="item.value"
                                               :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12">
                            <el-form-item label="医院地址:" prop="address">
                                <el-input clearable :disabled="isDisabled" v-model="hospitalConfig.address" size='small'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :sm="12">
                            <el-form-item label="经度:" prop="longitude">
                                <el-input clearable :disabled="isDisabled" v-model="hospitalConfig.longitude" size='small'></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12">
                            <el-form-item label="纬度:" prop="latitude">
                                <el-input clearable :disabled="isDisabled" v-model="hospitalConfig.latitude" size='small'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :sm="12">
                            <el-form-item label="医院网址:">
                                <el-input clearable :disabled="isDisabled" v-model="hospitalConfig.website" size='small'></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12">
                            <el-form-item label="是否挂号:" prop="isRegistered">
                                <el-select v-model="hospitalConfig.isRegistered" :disabled="isDisabled" placeholder="请选择" class='maxWidth'
                                           size='small'>
                                    <el-option v-for="item in global.DictionaryData['yesOrNo']" :key="item.value"
                                               :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :sm="12">
                            <el-form-item label="是否可咨询:" prop="isConsult">
                                <el-select v-model="hospitalConfig.isConsult" :disabled="isDisabled" placeholder="请选择" class='maxWidth'
                                           size='small'>
                                    <el-option v-for="item in global.DictionaryData['yesOrNo']" :key="item.value"
                                               :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="医院介绍:" prop="introduction">
                        <el-input clearable type="textarea" :disabled="isDisabled" resize="none" v-model="hospitalConfig.introduction"
                                  size='small'></el-input>
                    </el-form-item>
                    <el-form-item label="医院图片:" prop="imageUrl">
                        <div class="preImg">
                            <label class="btn" for="uploadA">
                                <img v-if="hospitalConfig.imageUrl" :src="hospitalConfig.imageUrl" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </label>
                            <input type="file" :disabled="isDisabled" ref='uploadInputA' id="uploadA" style="position:absolute; clip:rect(0 0 0 0);" accept="image/*" @change="uploadImgA($event)">
                            <upload-img ref='uploadImgA' :autoCropWidth='156' :autoCropHeight='120' :uploadDlgVisible='uploadDlgVisibleA'
                                        @hide='hideA' @handleSuccessImg='handleSuccessImgA'></upload-img>
                        </div>
                        <div style="color: #e6a23c;">图片比例为156*120</div>
                    </el-form-item>
                </el-form>

                <el-button type="primary" class="fr" style="margin-bottom: 20px" @click="submit" size="small">保存</el-button>
        </el-card>

<!--            </el-tab-pane>-->
<!--            <el-tab-pane label="挂号配置">-->
<!--                <el-table :stripe=true :height='tableHeight' class="w100" :data="doctorList">-->
<!--                    <el-table-column type="index" width="50" align="center"/>-->
<!--                    <el-table-column label="头像" align="center">-->
<!--                        <template slot-scope="scope">-->
<!--                            <img v-if="scope.row.headUrl" :src="scope.row.headUrl" width="60px" height="60px"/>-->
<!--                            <div v-else>暂无</div>-->
<!--                        </template>-->
<!--                    </el-table-column>-->

<!--                    <el-table-column label="姓名" prop="doctorName" align="center"></el-table-column>-->
<!--                    <el-table-column label="职称" prop="title" align="center" :formatter="formatTitle"></el-table-column>-->
<!--                    <el-table-column label="简介" prop="introduction" align="center"></el-table-column>-->
<!--                    <el-table-column label="操作" align="center">-->
<!--                        <template slot-scope="scope">-->
<!--                            <el-button size="mini" type='text' class='hkDanger' @click="editDoctor(scope.row)">编辑</el-button>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                </el-table>-->
<!--                <div class="toolBar mTop20 clearfix">-->
<!--                    <pagi-nation :page='page' @sizeChange='pageSizeChange'-->
<!--                                 @currentChange='pageCurrentChange'></pagi-nation>-->
<!--                </div>-->
<!--            </el-tab-pane>-->
<!--        </el-tabs>-->

        <el-dialog title="医生编辑" :visible.sync="isShowDoctorDlg" width="40%">
            <el-form label-width="120px" ref='doctorForm' :rules="formRules" :model='doctor' size='small'>
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="头像:">
                            <div class="preImg">
                                <label class="btn" for="uploadB">
                                    <img v-if="doctor.headUrl" :src="doctor.headUrl" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </label>
                                <input type="file" ref='uploadInputB' id="uploadB" style="position:absolute; clip:rect(0 0 0 0);" accept="image/*" @change="uploadImgB($event)">
                                <upload-img ref='uploadImgB' :autoCropWidth='150' :autoCropHeight='150' :uploadDlgVisible='uploadDlgVisibleB'
                                            @hide='hideB' @handleSuccessImg='handleSuccessImgB'></upload-img>
                            </div>
                            <div style="color: #e6a23c;">图片比例为150*150</div>
                            <!--<el-button type="text" @click="deleteImage" v-show="this.type==1 && editActivityForm.previewImage">移除图片</el-button>-->
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="姓名：" prop="doctorName">
                            <el-input clearable v-model="doctor.doctorName" placeholder="姓名" size='small'></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="职称：" prop="title">
                            <el-select clearable v-model="doctor.title" placeholder="请选择" class="w100"
                                       size='small'>
                                <el-option v-for="item in global.DictionaryData['title']" :key="item.value"
                                           :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="简介：" prop="introduction">
                            <el-input clearable size='small' v-model="doctor.introduction" placeholder="请输入医生简介"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isShowDoctorDlg = false" size='small'>取消</el-button>
                <el-button @click="commit" type="primary" size='mini'>确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import uploadImg from '@/components/uploadImg/uploadImg'
    class HospitalConfig {
        constructor() {
            this.address = null;
            this.hospitalId = null;
            this.imageUrl = null;
            this.introduction = null;
            this.isRegistered = null;
            this.isConsult = null;
            this.level = null;
            this.latitude = null;
            this.longitude = null;
            this.name = null;
            this.nature = null;
            this.orgId = null;
            this.phone = null;
            this.website = null;

        }

        reset() {
            this.constructor();
        }
    }

    class Doctor {
        constructor() {
            this.doctorId = null;
            this.doctorName = null;
            this.birthday = null;
            this.title = null;
            this.introduction = null;
            this.headUrl = null;
            this.showHeadUrl = null;
        }

        reset() {
            this.constructor();
        }
    }

    export default {
        data() {
            return {
                tabIndex: "0",
                hospitalConfig: new HospitalConfig(),
                rules: {
                    address: [{required: true, message: '地址不能为空', trigger: 'blur'}],
                    isRegistered: [{required: true, message: '请选择是否能挂号', trigger: 'blur'}],
                    isConsult:[{required: true, message: '请选择是否可咨询', trigger: 'blur'}],
                    introduction: [{required: true, message: '医院介绍不能为空', trigger: 'blur'}],
                    latitude: [{required: true, message: '经纬度不能为空', trigger: 'blur'}],
                    longitude: [{required: true, message: '经纬度不能为空', trigger: 'blur'}],
                    name: [{required: true, message: '医院名称不能为空', trigger: 'blur'}],
                    level: [{required: true, message: '医院等级不能为空', trigger: 'blur'}],
                    phone: [{required: true, message: '联系电话不能为空', trigger: 'blur'}],
                },
                page: new this.Page(),
                title: null,
                formVisible: false,
                menuForm: {},
                formRules: {
                    doctorName: [{required: true, message: '姓名不能为空', trigger: 'blur'}],
                    title: [{required: true, message: '职称不能为空', trigger: 'blur'}],
                    introduction: [{required: true, message: '简介不能为空', trigger: 'blur'}],
                },
                selectProps: {children: 'children', label: 'name', value: "menuId"},
                isShowDoctorDlg: false,
                doctor: new Doctor(),
                isShowDoctorAvatorDlg: false,
                uploadDlgVisibleA:false,
                uploadDlgVisibleB:false,
                isDisabled:true,
                tableHeight: document.documentElement.clientHeight - 330,
            };
        },
        components: {uploadImg},
        computed: {
        },
        methods: {
            pageSizeChange(val) {
                this.page.reset();
                this.page.pageSize = val;
                this.load();
            },
            pageCurrentChange(val) {
                this.page.currentPage = val;
                this.load();
            },
            formatTitle(row, column) {
                return this.getname('title', row.title);
            },

            formatSex(row, column) { //格式化性别
                return this.getname('sex', row.gender)
            },

            uploadImgA(e){
                this.uploadDlgVisibleA=true;
                this.$refs['uploadImgA'].uploadImg(e);
            },
            hideA(){
                this.$refs['uploadInputA'].value=null;
                this.uploadDlgVisibleA=false;
            },
            handleSuccessImgA(data){
                this.hospitalConfig.imageUrl = data;
                this.$message.success('上传成功！');
                this.hideA();
            },

            uploadImgB(e){
                this.uploadDlgVisibleB=true;
                this.$refs['uploadImgB'].uploadImg(e);
            },
            hideB(){
                this.$refs['uploadInputB'].value=null;
                this.uploadDlgVisibleB=false;
            },
            handleSuccessImgB(data){
                this.doctor.headUrl = data;
                this.$message.success('上传成功！');
                this.hideB();
            },
            async getHospitalInfo() {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let res = await this.$http.post('/sys/getHospitalInfo', {orgId: orgId});
                if (res.state) {
                    if (res.data) {
                        Object.assign(this.hospitalConfig, res.data);
                    }
                }
            },
            load() {
                if (!this.global.orgId.length) {
                    this.$message.warning('请先选择机构');
                    return;
                }
                if (this.tabIndex == "0") {
                    this.getHospitalInfo();
                } else if (this.tabIndex == "1") {
                    this.getHospitalDoctor();
                }
            },
            handleChange(val) {
                if (val) {
                    localStorage.setItem('orgId',JSON.stringify(val));
                    this.isDisabled = false;
                    this.hospitalConfig.reset();
                    this.getHospitalInfo();
                    this.load()
                }
            },
            async updHospitalInfo() {
                let res = await this.$http.post('/sys/updHospitalInfo', this.hospitalConfig);
                if (res.state) {
                    this.$message.success('保存成功');
                    this.getHospitalInfo();
                }
            },
            submit() {
                if (!this.global.orgId.length) {
                    this.$message.warning('请先选择机构');
                    return;
                }
                this.$refs['form'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.updHospitalInfo();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            init() {
                if (this.global.orgId.length) {
                    this.isDisabled = false;
                    this.hospitalConfig.reset();
                    this.getHospitalInfo();
                    this.load()
                }else {
                    setTimeout(() => {
                        this.init()
                    }, 200)
                }
            },
        },
        created() {
            this.init()
            /*this.getoptions('hospitalLevel');
            this.getoptions('yesOrNo');
            this.getoptions('title');
            this.getoptions('sex');*/
        },
        mounted() {
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight;
                this.tableHeight = window.fullHeight - 283;
            }
        }
    };
</script>
<style scoped>
    .preImg {
        display: flex;
        align-items: center;
    }

    .avatar-uploader-icon {
        display: inline-block;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }
    .avatar-uploader-icon:hover {
        border-color: #aaa;
    }

    .avatar {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        max-width: 120px;
        display: inline-block;
        vertical-align: middle;
    }
</style>
