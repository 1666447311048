import { render, staticRenderFns } from "./hospitalConfig.vue?vue&type=template&id=ad5de9de&scoped=true"
import script from "./hospitalConfig.vue?vue&type=script&lang=js"
export * from "./hospitalConfig.vue?vue&type=script&lang=js"
import style0 from "./hospitalConfig.vue?vue&type=style&index=0&id=ad5de9de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad5de9de",
  null
  
)

export default component.exports